import React, { FC } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { PageProps } from "gatsby";
import { Layout } from "../components/Layout/Layout";
import { PageHeader } from "../components/PageHeader/PageHeader";
import { SEO } from "../components/SEO/SEO";
import { Callout } from "../components/Callout/Callout";
import styles from "../scss/pages/404.module.scss";

export const fourOhFour: FC<PageProps> = () => {
	return (
		<Layout>
			<SEO title="Page not found" description="No page at this address!" />
			<div className={styles.imageContainer}>
				<Player autoplay loop speed={1} src="/json/404-smiley.json" />
			</div>
			<PageHeader
				heading="Wrong turn!"
				lead="The page you're looking for was removed, moved, renamed, quantum leaped or might never have existed."
			/>
			<Callout />
		</Layout>
	);
};

export default fourOhFour;
